/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const routes = /(confirmation|invite|recovery|email_change)_token=([^&]+)/;
const errorRoute = /error=access_denied&error_description=403/;
const accessTokenRoute = /access_token=/;

exports.onInitialClientRender = (
    _,
    { enableIdentityWidget = true, publicPath = `admin` },
) => {
    const hash = (document.location.hash || ``).replace(/^#\/?/, ``);
    // Instagram auth and Netlify auth collide. Disable netlify on the Instagram auth page.
    const isInstagram = window.location.pathname.startsWith("/instagram");

    if (
        enableIdentityWidget &&
        !isInstagram &&
        (routes.test(hash) ||
            errorRoute.test(hash) ||
            accessTokenRoute.test(hash))
    ) {
        import(`netlify-identity-widget`).then(
            ({ default: netlifyIdentityWidget }) => {
                netlifyIdentityWidget.on(`init`, user => {
                    if (!user) {
                        netlifyIdentityWidget.on(`login`, () => {
                            document.location.href = `${__PATH_PREFIX__}/${publicPath}/`;
                        });
                    }
                });
                netlifyIdentityWidget.init();
            },
        );
    }
};
