module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bay Equity","short_name":"be","start_url":"/","background_color":"#FF8A00","theme_color":"#FF8A00","display":"minimal-ui","icon":"src/images/bayequity_circle_mark.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"31e80b7a8d3b5ed1f05e3a6920f71529"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"slug","store":true},{"name":"type","store":true},{"name":"title","store":true},{"name":"branch","store":true},{"name":"photo","store":true},{"name":"phone","store":true},{"name":"address","store":true},{"name":"city","store":true},{"name":"state","store":true},{"name":"zip","store":true},{"name":"lat","store":true},{"name":"lon","store":true},{"name":"license","store":true},{"name":"searchVisibility","store":true}],"resolvers":{"Profile":{}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
